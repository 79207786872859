import { sendAnalyticsEvent, useSession } from '@faststore/sdk'
import { useEffect, useState } from 'react'
import { GTMUserEvent } from '../types'

export const useUserEvent = (pageType: string) => {
  const { person, isValidating } = useSession()
  const [hasSentEvent, setHasSentEvent] = useState<boolean>(false)

  useEffect(() => {
    if (!hasSentEvent && !isValidating) {
      const personHashPromise = new Promise<string | null>(
        (resolve, reject) => {
          try {
            if (!person?.email) {
              resolve(null)
            } else {
              const msgBuffer = new TextEncoder().encode(person?.email)

              crypto.subtle
                .digest('SHA-256', msgBuffer)
                .then((hashBuffer) => {
                  const hashArray = Array.from(new Uint8Array(hashBuffer))
                  const hashHex = hashArray
                    .map((b) => b.toString(16).padStart(2, '0'))
                    .join('')

                  resolve(hashHex)
                })
                .catch((error) => {
                  reject(error)
                })
            }
          } catch (error) {
            reject(error)
          }
        }
      )

      personHashPromise.then((hashedEmail) => {
        sendAnalyticsEvent<GTMUserEvent>({
          name: 'gtm_user_event',
          params: {
            loginStatus: person?.id ? 'logged' : 'not logged',
            userID: person?.id ?? '',
            visitorType: '',
            visitorContactInfo: {
              nome: person?.givenName ?? '',
              cognome: person?.familyName ?? '',
              email: person?.email ?? '',
              hashed_email: hashedEmail ?? '',
            },
            visitorDemographicInfo: {
              indirizzo: '',
              città: '',
              provincia: '',
              cap: '',
            },
            visitorOptinNewsLetter: '',
            pageType: pageType,
          },
        })

        setHasSentEvent(true)
      })
    }
  }, [person, setHasSentEvent, hasSentEvent, isValidating])
}
