type GenericObject = { [key: string]: any }

export const deepObjectCopy = (obj: GenericObject) => {
  return JSON.parse(JSON.stringify(obj))
}

const getTypeOfPage = (type: string) => {
  if (!type) {
    return ''
  }

  const categories: Record<string, string> = {
    Category: 'categoryId',
    Department: 'categoryId',
    SubCategory: 'categoryId',
    Brand: 'brandId',
    Collection: 'clusterId',
  }

  return categories[type] ?? ''
}

export const getPlpSections = ({
  allPlpNodes,
  collection: currentCollection,
}: GetPlpSectionsProps): Section[] => {
  const genericSections: Section[] = []
  const plpSections: Section[] = []

  const typeOfCurrentPage = getTypeOfPage(currentCollection?.type)

  for (const plpNode of allPlpNodes) {
    const parametersFromNode = plpNode?.node?.parameters?.collection

    if (!currentCollection?.id && !parametersFromNode?.generic) {
      continue
    }

    const isAValidSection =
      currentCollection?.id &&
      currentCollection?.id ===
        parametersFromNode?.[typeOfCurrentPage as keyof PlpCollection]

    if (isAValidSection) {
      plpSections.push(...plpNode.node.sections)
    }

    if (parametersFromNode?.generic) {
      genericSections.push(...plpNode?.node?.sections)
    }
  }

  return plpSections.length ? plpSections : genericSections
}
